.contfooter{
    background-color: #000000;
    color: #ffffff;
    padding: 20px;
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0px;
    position: relative;
    bottom: 0;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 100px;
}

.contfooter1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 70px;
}

.textfooter{
    font-size: 15px;
    font-weight: 300;
    color: #f9b714;
}

.contfooter2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
}

.texttransp{
    color: #f9b714;
    font-size: 17px;
}

.conticon{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.iconfooter{
    width: 25px;
    height: 30px;
    color: #f9b714;
    cursor: pointer;
}

@media screen and (max-width: 768px){
    .contfooter{
        height: 100%;
    }
    .contfooter1{
        align-items: center;
    }
    .contfooter2{
        align-items: center;
    }
}