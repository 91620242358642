@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}

.contenedorglobalheaderp{
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.uno{
    height: 20%;
    background-color: #0F50A3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.dos{
    width: 100%;
    height: 30%;
    background-color: #F8B901;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 0;
    gap: 10px;
}

.tres{
    width: 100%;
    height: 40%;
    background-color: #0F50A3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.buttonmenu{
    right: 0px;
    position: absolute;
    color: white;
    font-size: 30px;
    margin-right: 20px;
}

.textologo{
    color: #0F50A3;
    font-size: 15px;
    font-weight: bold;
    margin-left: 10px;
}

.titlesomos{
    color: white;
    font-size: 22px;
    font-weight: lighter;
}

.logop{
    width: 170px;
}

.menu{
    width: 100%;
    height: 50%;
    background-color: #F8B901;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    transition: all 0.5s ease;
}

.enlacesmenu{
    color: #fff;
    text-decoration: none;
    list-style: none;
    font-size: 25px;
}

.buttonmenu{
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 32px;
}

.x{
    color: #F8B901;
    padding: 5px 10px;
    background-color: #0F50A3;
    font-size: 22px;
    height: 40px;
    width: 45px;
    margin-right: 20px;
    margin-top: 10px;
    font-weight: bold;
    cursor: pointer;
}

@media screen and (max-width: 768px){
    .uno{
        height: 15%;
    }

    .dos{
        height: 35%;
    }

    .tres{
        height: 50%;
    }

    .logop{
        width: 150px;
    }

    .textologo{
        font-size: 12px;
    }

    .titlesomos{
        font-size: 18px;
    }
}