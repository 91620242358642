/* Slider Container */
.slider-container {
    position: relative;
    width: 100%;
    max-width: 100%; /* Asegurarse de que el contenedor ocupe el 100% */
    margin: 0 auto;
    overflow: hidden;
  }
  
  /* Slider */
  .slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%; /* Asegurarse de que el slider ocupe el 100% */
  }
  
  /* Slide */
  .slide {
    flex: 0 0 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
    width: 100%; /* Asegurarse de que cada slide ocupe el 100% */
  }
  
  .slide.active {
    opacity: 1;
    transform: translateX(0);
  }
  
  /* Navigation Buttons */
  .prev-button,
  .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 15px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    z-index: 1;
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }
  