.contcielo{
    background-color: #DFEDF3;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    padding: 80px 0px;
}

.contcielo__cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.contcielo__cont__textimg{
    width: 60%;
}

.contcamiontexto{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100vh;
}

.camionmabel{
    width: 400px;
}

.textobtn{
    width: 400px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textobtn__h1{
    font-size: 25px;
    font-weight: lighter;
    color: #0C3C7A;
    margin-bottom: 20px;
}

.btnpagar{
    width: 250px;
    height: 50px;
    background-color: #0F50A3;
    border-radius: 9999px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    font-weight: bold;
}

@media screen and (max-width: 768px){
    .contcielo__cont__textimg{
        width: 100%;
    }

    .contcamiontexto{
        flex-direction: column;
    }

    .camionmabel{
        width: 300px;
        margin-bottom: 50px;
    }

    .textobtn{
        width: 100%;
        margin-left: 0px;
    }

    .textobtn__h1{
        font-size: 20px;
    }

    .btnpagar{
        width: 200px;
        height: 40px;
        font-size: 15px;
    }
}