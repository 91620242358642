
.contglobalvehiculos{
    padding: 45px 20px 45px 20px;
}

.contvehiculos{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
}

.camiones{
    width: 420px;
    height: 250px;
}

@media screen and (max-width: 425px){
    .camiones{
        width: 100%;
        height: 100%;
    }
}