
.contformularioicon{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.formulariocontacto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 65%;
}

.logobl{
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.iconl{
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    color: #fff;
    cursor: pointer;
}

.conticonl{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 35%;
}

.labelform{
    font-size: 17px;
    color: #000;
    font-weight: bolder;
    margin-bottom: 10px;
}

.inputform{
    width: 100%;
    height: 40px;
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    padding-left: 15px;
    margin-bottom: 10px;
}

.inputform::placeholder{
    color: #d4cfcf;
    font-weight: bolder;
}

.texttareaform{
    width: 100%;
    height: 100px;
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    padding-left: 15px;
    margin-bottom: 10px;
}

.contenedorbotonescontactar{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px 0px;
    width: 100%;
}

.texttareaform::placeholder{
    color: #d4cfcf;
    font-weight: bolder;
}

.buttonform{
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 20px;
    background-color: #f8b901;
    color: #fff;
    font-weight: bolder;
    cursor: pointer;
    margin-bottom: 10px;
}

.contmapa{
    background-color: #f8b901;
    padding: 40px;
    height: 100%;
}

.mapa{
    width: 100%;
    height: 400px;
    border-radius: 0px;
}

@media screen and (max-width: 670px){
    .contenedorbotonescontactar{
        flex-direction: column;
    }
}

@media screen and (max-width: 425px){
    .formulariocontacto{
        width: 65%;
    }
    .conticonl{
        width: 25%;
        margin-left: 25px;
    }
}