*{
    margin: 0;
}

.cielo{
    background-color: #DFEDF3;
    height: 100%;
    padding-bottom: 120px;
}

.btncotiza{
    background-color: #0F50A3;
    color: white;
    border: none;
    padding: 10px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 70px;
    padding: 20px 45px 20px 45px;
    margin-bottom: 20px;
    text-decoration: none;
}

.cielo__cont{
    display: flex;
    flex-direction: row;
    height: 100%;
    flex-wrap: wrap;
    flex: 1 1;
    padding: 0px 10px 0px 100px;
    margin: 0px;
}

.cielo__cont__text{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 30px;
    float: right;
    padding-top: 145px;
}

.cielo__cont__texth1{
    text-align: start;
    color: #F9B800;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    width: 370px;
}

span{
    font-weight: lighter;
    color: #0E4387;
    font-size: 20px;
}

.contcasa{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 145px;
}

.casamabel{
    width: 400px;
}

@media screen and (max-width: 1024px){
    .cielo__cont{
        padding: 0px 10px 0px 10px;
    }

    .cielo__cont__text{
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
        justify-content: center;
        align-items: center;
    }

    .cielo__cont__texth1{
        width: 100%;
        font-size: 25px;
        margin-top: 20px;
        text-align: center;
    }

    .contcasa{
        width: 100%;
        padding-top: 0px;
    }

    .casamabel{
        width: 60%;
    }
}

@media screen and (max-width: 768px){
    .cielo__cont{
        padding: 0px 10px 0px 10px;
    }

    .cielo__cont__text{
        justify-content: center;
        align-items: center;
        gap: 0px;
    }

    .cielo__cont__text{
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
    }

    .cielo__cont__texth1{
        width: 100%;
        font-size: 25px;
        margin-top: 20px;
        text-align: center;
}

    .contcasa{
        width: 100%;
        padding-top: 0px;
    }

    .casamabel{
        width: 100%;
    }
}
