@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}

.contenedorcaracteristicas{
    height: 100%;
    padding: 50px 20px;
    background: #0F50A3;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.titlecaracteristicas{
    color: #fff;
    font-size: 30px;
}

.contcaraceimg{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 35px;
    align-items: center;
    flex-wrap: wrap;
}

.d{
    text-align: end;
}

.i{
    text-align: start;
}

.textocaracteristica{
    width: 60%;
    background-color: #fff;
    padding: 25px 15px;
    border-radius: 20px;
    border: solid 1px #000;
}

@media screen and (max-width: 768px){
    .contcaraceimg{
        flex-direction: column;
    }

    .textocaracteristica{
        width: 90%;
        border-radius: 10px;
        font-size: 14px;
        text-align: justify;
    }
    .contenedorcaracteristicas{
        padding: 20px 10px;
    }

    .titlecaracteristicas{
        font-size: 18px;
    }

    .ib{
        display: none;
    }
}

