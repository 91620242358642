.contenedorglobal{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1F2937;
}

.contenedorlibroreclamos{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imgpersona{
    width: 100px;
    height: 100px;
}

.titlereclamo{
    color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    margin: 0px;
}

.contlibro{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.conlibro__libro{
    background-color: #ffffff;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 25px;
    gap: 25px;
    margin-top: 45px;
}

.imglibro{
    width: 240px;
}

.contlibro__libro__text{
    font-size: 18px;
    text-align: justify;
    color: #4B5563;
    width: 250px;
}

.btnreclamo{
    background-color: #0F50A3;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    padding: 15px 20px;
    border: none;
    text-decoration: none;
    cursor: pointer;
}

.contlibro__libro2{
    background-color: #F9B714;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 45px 25px;
    gap: 25px;
    margin-top: 45px;
}

.contlibro__libro2__text{
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    width: 240px;
}

.btnterminos{
    background-color: #0F50A3;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    padding: 15px 20px;
    border: none;
    text-decoration: none;
    cursor: pointer;
    width: 85%;
}

@media screen and (max-width: 768px){
    .contenedorglobal{
        height: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
        justify-content: center;
        align-items: center;
    }
    .contenedorlibroreclamos{
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .contlibro{
        flex-direction: column;
    }
    .conlibro__libro{
        width: 100%;
    }
    .contlibro__libro2{
        width: 100%;
    }
}

@media screen and (max-width: 425px){
    .contlibro__libro__text{
        width: 100%;
    }
    .contlibro__libro2__text{
        width: 100%;
    }
    .contenedorlibrodereclamos{
        padding-left: 45px;
        justify-content: center;
        align-items: center;
    }
}

