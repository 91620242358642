.card{
    width: 260px;
    height: 330px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card__cont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out;
    gap: 25px;
    padding-bottom: 35px;
}

.card__cont:hover{
    background-color: #0F50A3;
    color: #ffffff;
}

.card__cont:hover .card__cont__texth1{
    color: #ffffff;
}

.card__cont__texth1{
    font-size: 20px;
    font-weight: bold;
    color: #0F50A3;
    text-align: center;
}

.icon{
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}

.card__cont__text{
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 50%;
}