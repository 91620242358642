
* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

.contcardsglobal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 55px;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(to bottom, #dfedf3 20%, #0F50A3 20%);
    padding-bottom: 100px;
}

/* ESTILOS SEGUNDA PAGINA PRINCIPALDOS */

.buttonmenu{
    margin-top: 25px;
}

.color-div {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imglogopd{
    width: 180px;
    height: 180px;
    margin-top: 50px;
}

.desktop-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-top: 20px;
}

.logo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.menu {
    list-style: none;
    display: flex;
    gap: 30px;
}

.enlacesmenu {
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.textologo{
    color: #fff !important;
    mix-blend-mode: difference;
}

.contimg{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    height: 500px;
    width: 90%;
    overflow: hidden;
    position: relative;
    padding-top: 45px;
}

.contcamuno{
    position: absolute;
    width: 300px;
    height: 300px;
    margin-right: 450px;
}

.camionimgpd{
    width: 300px;
    position: absolute;
    bottom: 0;
    left: 150;
    z-index: 2;
    border-radius: 5px 5px 0 0;
}



.contactopd{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 35px;
}

.logotitle{
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: start;
    gap: 10px;
}

.redesps__title{
    font-size: 24px;
    color: #ffffff !important;
}

.redespd{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 10px;
    margin-top: 25px;
}

.redespd__cont{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 10px;
}

.cr{
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
}

.iconrd{
    width: 40px;
    height: 40px;
    text-align: start;
    color: #ffffff;
}

.crr{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    gap: 7px;
}

/* Agrega estos estilos al archivo Principal.css */

/* Menú */
.menu {
    background-color: red;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.logo img {
    width: 100px;
    height: auto;
}

.menu-toggle {
    font-size: 24px;
    cursor: pointer;
}

.menu-links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.menu-links a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    transition: color 0.3s;
}

.menu-links a:hover {
    color: #f9f9f9;
}

/* Estilos para el menú móvil */
.show-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: red;
    border-top: 1px solid #f9f9f9;
    z-index: 999;
}

.show-menu li {
    padding: 10px 20px;
    border-bottom: 1px solid #f9f9f9;
}

.show-menu a {
    font-size: 18px;
}

/* Estilos para la animación hover del menú */
.navbar-nav .nav-item {
    position: relative;
}

.nav-item::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: red;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.3s ease-in-out;
}

.nav-item:hover::after {
    width: 100%;
}

/* Otros estilos para el menú y el resto del contenido */
/* ... */


.enlpd{
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.tpd{
    font-size: 14px;
    font-weight: lighter;
    color: black;
    text-decoration: none;
    color: #fff;
}

.textopd__title{
    font-size: 22px;
    color: #fff;
}

.textopd__parrafo{
    color: #fff;
}

.contsomospd{
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 80%;
    background-color: white;
    border-radius: 10px;
    padding: 25px 20px;
    border: 1.5px solid #E0E0E0;
    gap: 15px;
}

.conocemas{
    color: white;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
}

.btnconocemas{
    background-color: #D90007;
border-radius: 10px;
width: 100%;
height: 45px;
border: none;
}

.contseccion{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.contdesc{
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contdesc__title{
    font-size: 22px;
    font-weight: bold;
}

.contdesc__parrafo{
    font-size: 15px;
    font-weight: lighter;
}

.textosomospd{
    font-size: 24px;
    color: #fff;
    text-align: center;
    margin-bottom: 12px;
}

.contseccionpresentacion{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 25px;
    margin-top: 65px;
}

.contvalores{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    width: 550px;
    height: 100%;
    border-radius: 10px;
    padding: 25px 20px;

}

.dv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    justify-content: flex-start;
}

.cp{
    text-align: justify;
    border: 2px solid #E0E0E0;
    border-radius: 20px;
    padding: 20px 25px;
    border-radius: 15px;
    width: 420px;
}

.camionredes{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.imagencm{
    width: 550px;
    height: 100%;
    float: inline-end;
    border-radius: 10px;
}

.contredessoc{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: start;
    margin-top: 25px;
}

.iconv{
    width: 50px;
    height: 50px;
    text-align: start;
    cursor: pointer;
}

.iconv:hover{
    transform: scale(1.1);
}

.contvideodesc{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 55px;
    height: 100%;
    padding: 85px 20px;
}

.lbs{
    width: 175px;
}

.contdescvideo__parrafo{
    font-size: 15px;
    font-weight: lighter;
    text-align: start;
    margin-top: 15px;
    margin-bottom: 15px;
}

.contdescvideo{
    width: 450px;
}

.contbannerof{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 55px 10px;
    flex-wrap: wrap;
    gap: 25px;
}

.itembo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 350px;
}

.iconbo{
    width: 30px;
    height: 30px;
    color: #0F50A3;
}

.tl{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.tp{
    font-size: 15px;
    font-weight: lighter;
    text-align: center;
    width: 300px;
}

.campri{
    width: 700px;
    height: 92%;
}

.camiondosimgpd{
    display: none;
}

@media screen and (max-width: 1085px) {
    .contseccion{
        flex-direction: column;
        gap: 100px;
        padding: 0px;

    }
    .contactopd{
        width: 100%;
        align-items: start;
        justify-content: start;
        padding: 15px;
        margin-right: 15px;
    }
    .textopd__title{
        font-size: 16px;
    }
    .textopd__parrafo{
        font-size: 14px;
    }
    .redesps__title{
        font-size: 16px;
    }
    .tpd{
        font-size: 12px;
    }
    .enlpd{
        font-size: 14px;
    }
    .contseccionpresentacion{
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 800px){
    .contvideodesc{
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 585px){
    .contseccion{
        gap: 50px;
    }
    .imagencm{
        width: 100%;
        height: 100%;
    }
    .contvalores{
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 50px;
    }
    .cp{
        width: 100%;
    }
    .camionredes{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .contsomospd{
        width: 100%;
        height: 100%;
    }
    .contdesc__title{
        font-size: 18px;
    }
    .contdesc__parrafo{
        font-size: 14px;
    }
    .contseccionpresentacion{
        padding: 10px;
    }
}

@media screen and (max-width: 520px){
    .contsomospd{
        width: 95%;
        height: 100%;
    }
    .contdesc__title{
        font-size: 18px;
    }
    .contdesc__parrafo{
        font-size: 14px;
    }
}

@media screen and (max-width: 720px){
    .campri{
        width: 100%;
        height: 80%;
    }
}

@media screen and (max-width: 600px){
    .campri{
        display: none;
    }
    .camiondosimgpd{
        display: block;
        width: 90%;
        height: 80%;
        border-radius: 50%;
        z-index: 1;
    }
}