
.contbanner{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    flex-direction: row;
    padding: 100px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.contbannertexto{
    text-align: start;
    font-size: 27px;
    font-weight: lighter;
}

.spanbanner{
    font-weight: bold;
    color: black;
    font-size: 22px;
}

.contbannerimg{
    width: 200px;
}

.lineaamarilla{
    background-color: #D90007;
}

.lineatexto{
    color: #fff;
    font-size: 20px;
    padding: 25px 5px;
}

.contenedorbotones{
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px 0px;
    justify-content: center;
    align-items: center;
}

.btncontactarb{
    background-color: #0e50a3;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 15px 20px;
    font-size: 18px;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.botonllamarlima{
    display: flex;
    flex-direction: column;
}

.contbotonesllamar{
    display: flex;
    flex-direction: row;
    gap: 45px;
    padding: 25px 0px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.botonllamarlc{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.btnlima{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #D90007;
    padding: 20px 30px;
    gap: 10px;
    border-radius: 15px;
}

.botonllamar{
    text-decoration: none;
    color: #fff;
}

.iconllamar{
    font-size: 30px;
    color: #fff;
}

.footerbanner{
    background-color: #000;
    padding: 15px 0px;
}

.textofooterb{
    color: #fff;
}

.spanfooterb{
    color: #D90007;
    font-size: 17px;
}

@media screen and (max-width: 768px){
    .contbanner{
        padding: 50px 20px;
        gap: 25px;
    }
    .contbannertexto{
        font-size: 18px;
        text-align: center;
    }
    .contbannerimg{
        width: 180px;
    }
    .lineatexto{
        font-size: 14px;
    }
}