@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

*{
    font-family: 'Inter', sans-serif;
}

.contglobal{
    display: flex;
    flex-direction: column;
}

.Navcont{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #0E4387;
    height: 60px;
    overflow: hidden;
    position: relative;
}

.numerostel{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    color: #F9B800;
    font-size: 20px;
    gap: 10px;
    margin-left: 20px;
}

.numerostel__text{
    margin-bottom: 0px;
    margin-top: 2px;
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.numbers{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    height: 60px;
}

.tarifa{
    padding-right: 15px;
}

.tarifa__link{
    color: white;
    text-decoration: none;
    font-size: 13px;
    font-weight: bold;
}

.navp{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 120px;
    overflow: hidden;
    background-color: #0F50A3;
    margin-bottom: 0px;
}

.navp__logoimg{
    width: 170px;
    height: 60px;
    margin-left: 20px;
}

.navp__menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-right: 20px;
}

.navp__menuul{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    list-style: none;
    margin-bottom: 0px;
    height: 100%;
}

.navp__menulink{
    font-size: 25px;
    color: #ffffff;
    text-decoration: none;
}

.navp__redes{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-right: 45px;
}

.navp__redeslink{
    color: #F9B800;
    text-decoration: none;
    font-size: 30px;
}



.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburger .line {
    width: 25px;
    height: 3px;
    background-color: #F9B800;
    margin: 3px 15px 3px 0;
  }
  
  @media (max-width: 800px) {
    .navp__menu {
      display: none;
    }
  
    .hamburger {
      display: flex;
    }

    .nav-link{
        color: white !important;
    }
  
    .show {
      display: flex;
      flex-direction: column;
      align-items: center;
        justify-content: center;
      font-size: 15px;
      width: 190px;
      height: 250px;
      position: absolute;
      top: 130px;
      right: 5px;
      background-color: #f30000;
      color: #ffffff;
      padding: 55px;
      z-index: 2;
    }

    .navp__menuul{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 20px;
        list-style: none;
        margin-bottom: 0px;
        height: 100%;
    }
}

      @media screen and (max-width: 425px) {
        .navp__logoimg{
            width: 120px;
            height: 40px;
            margin-left: 20px;
        }

        .navp__redeslink{
            font-size: 24px;
        }
        .navp__redes{
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
      }