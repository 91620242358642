
.contenedorcamion{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F9B800;
    padding: 170px 0px;
}

.camioninfo{
    width: 600px;
}

@media screen and (max-width: 768px){
    .camioninfo{
        width: 80%;
    }
    .contenedorcamion{
        padding: 100px 0px;
    }
}