.contpaisaje{
    width: 100%;
    height: 100vh;
    background-color: #daf8ff;
    position: relative;
}

.contpaisajetags{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 60%;
    padding-top: 50px;
    overflow: hidden;
}

.imgpaisaje{
    width: 100%;
    position:absolute;
    bottom:0px;
    left: 0px;
}

.tags{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 240px;
    width: 150px;
    justify-content: space-around;
    align-items: center;
}

.paisajeicon{
    width: 100px;
    height: 100px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.paisajetitle{
    color: #0F50A3;
    font-size: 23px;
}

@media screen and (max-width: 425px){
    .paisajeicon{
        width: 75px;
        height: 75px;
    }

    .paisajetitle{
        font-size: 18px;
    }
}